import React, { useState } from "react";
import { Layout, SEO, DefaultHeader, Footer } from "components";
import { graphql } from "gatsby";
import styled from "styled-components";
import { motion } from "framer-motion";
import { P } from "../mixins";
import { FadeInUp } from "components";

export const query = graphql`
  query AboutPageQuery {
    banner: file(relativePath: { eq: "assets/images/about-banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const AboutPage = (props) => {
  const { data, errors } = props;
  const banner = (data || {}).banner;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleChange = (e) => {
    const changedElement = e.target;
    const { name, value } = changedElement;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...formValues,
      }),
    })
      .then(() => setFormSubmitted(true))
      .catch((error) => alert(error));
  };

  return (
    <Layout>
      <SEO title="About Us" description="At LHS Services we have a team of professional, experienced and highly competent individuals waiting to work with your company." />
      <DefaultHeader
        title="about us"
        description="At LHS Services we have a team of professional, experienced and highly competent individuals waiting to work with your company."
        imageFluid={banner}
      />
      <Container>
        <Content>
          <FadeInUp>
            <P>
              We are an Irish based statutory inspection company with over 30 years of expertise in
              testing, certification and repairs. We offer a full-service statutory inspection
              solution including bespoke designing of inspections, testing, certification and
              repairs. We currently service some of Irelands largest blue-chip companies.
            </P>
            <P>
              Our team and knowledge base has been carefully curated to deliver a best in class
              service to the evolving requirement of modern business from manufacturing plants,
              specialist facilities management companies to the emerging digital and data sector.
            </P>
            <P>
              We think of ourselves as “Your Partner in Safety”, and through our Award Winning
              C.L.A.S.S. online safety software, LHS is at your service 24/7.
            </P>
            <P>
              Continual innovation is at the core of everything we do, and we look forward to being
              of service to you and your business.
            </P>
            <P>
              <strong>Get in touch today.</strong>
            </P>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9636.68364485416!2d-6.6933664078654385!3d53.30293826668845!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x51dea94eb52f56f8!2sLifting%20and%20Handling%20Services!5e0!3m2!1sen!2sie!4v1610552613066!5m2!1sen!2sie"
              width="auto"
              height="auto"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </FadeInUp>
        </Content>
        {!formSubmitted && (
          <ContactForm
            id="contactform"
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
          >
            <input type="hidden" name="contact-form" value="contact" />
            <label htmlFor="name">Name:</label>
            <input
              type="text"
              name="name"
              placeholder="Enter Your Name"
              onChange={handleChange}
              required
            />
            <label htmlFor="email">E-mail Address:</label>
            <input
              type="email"
              name="email"
              placeholder="Enter Your Email"
              onChange={handleChange}
              required
            />
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              name="subject"
              placeholder="Enter Subject"
              onChange={handleChange}
              required
            />
            <label htmlFor="message">Inquiry:</label>
            <textarea
              name="message"
              placeholder="Enter Your Message"
              onChange={handleChange}
              required
            ></textarea>
            <Terms><input type="checkbox" id="terms_and_conditions" checked={termsAccepted} value="1" onChange={()=>{setTermsAccepted(!termsAccepted)}} /><p>I agree to having the above details stored for future communications.</p></Terms>
            <Button type="submit" disabled={!termsAccepted}>Submit</Button>
          </ContactForm>
        )}
        {formSubmitted && (
          <ThankYou
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <p>
              Thank you! We have received your message and will endeavour to respond as soon as
              possible.
            </p>
          </ThankYou>
        )}
      </Container>
      <Footer />
    </Layout>
  );
};

const Terms = styled.div`
display:flex;
justify-content:flex-start;
align-items:flex-start;
input[type=checkbox]{
  width:1rem;
  margin-right: 1rem;
  border: 1px solid white;
}
p{
  color: #747474;
  font-size:.875rem;
  line-height: 1.5em;
}
`

const Button = styled.button`
  outline: none;
  border: none;
  background: #f94c00;
  color: white;
  width: 8.75rem;
  height: 3rem;
  margin-left: 0.5rem;
  font-size: 0.875rem;
  padding: 0.5rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;
  transition: 0.5s;
  cursor: pointer;
  box-shadow: 4px 4px 10px rgba(3, 20, 103, 0.3);
  &:disabled{
    cursor: not-allowed;
    opacity: .75;
  }
`;

const ThankYou = styled(motion.div)`
  grid-area: form;
  background: #eaf6ff;
  max-height: 150px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.425rem;
  margin-top: 1.5rem;
  p {
    margin: 2rem 0;
    text-align: center;
  }

  @media (min-width: 1150px) {
    grid-column: 2/6;
    padding: 2rem;
  }
`;

const ContactForm = styled.form`
  width: 100%;
  grid-area: form;
  background: #eaf6ff;
  padding: 2rem 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 0.425rem;
  height: auto;
  input {
    height: 2.5rem;
    width: 100%;
    background: white;
    border: none;
    outline: none;
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    margin-bottom: 2rem;
    font-style: italic;
    border-radius: 0.25rem;

    &::placeholder {
      color: #959c9b;
    }
    &:hover {
      border: none;
      outline: none;
    }
  }
  label {
    font-size: 1.25rem;
    color: #243f93;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  textarea {
    height: 150px;
    max-height: 100%;
    width: 100%;
    border: none;
    outline: none;
    background: white;
    font-size: 0.9rem;
    padding: 0.5rem 0.75rem;
    margin-bottom: 2rem;
    font-style: italic;
    &::placeholder {
      color: #959c9b;
    }
    &:hover {
      border: none;
      outline: none;
    }
  }
  @media (min-width: 768px) {
    max-width: 700px;
    margin: 0 auto;
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/6;
    padding: 3rem 2rem;
    max-height: 650px;
    textarea {
      height: 280px;
    }
  }
`;

const Content = styled.div`
  grid-area: textcontent;
  width: 100%;
  display: flex;
  flex-direction: column;
  iframe {
    border: none;
    width: 100%;
    height: 250px;
  }
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 7/12;
  }
`;

const Container = styled.section`
  width: 1440px;
  max-width: 100%;
  margin: 5rem auto;
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 3rem;
  padding: 1rem;
  grid-template-areas: "textcontent" "form";
  @media (min-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 2rem;
  }
  @media (min-width: 1150px) {
    grid-column-gap: 0rem;
    grid-template-areas: "form textcontent";
    grid-template-columns: repeat(12, 1fr);
  }
`;

export default AboutPage;
