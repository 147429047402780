import styled from 'styled-components'

export const breakpoints = {
  xs: '@media (min-width: 480px)',
  sm: '@media (min-width: 768px)',
  md: '@media (min-width: 992px)',
  lg: '@media (min-width: 1150px)',
  xl: '@media (min-width: 1441px)'
}

export const Button = styled.button`
  width: auto;
  height: 2rem;
  color: white;
  background: #f94c00;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const P = styled.p`
  font-weight: 300;
  font-size: 1rem;
  line-height: 1.5em;
  color: #232528;
`

export const H3 = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.5em;
  color: #223f92;
  @media (min-width: 1150px) {
    font-size: 1.25rem;
  }
`

export const UL = styled.h3`
  list-style: none;
  margin-left: 2rem;
  width: calc(100% - 2rem);
`

export const LI = styled.li`
  color: #f94c00;
  position: relative;
  font-size: 0.875rem;
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 300;
  svg {
    width: 0.875rem;
    position: absolute;
    top: 0.425rem;
    left: -2rem;
  }
`

export const Content = styled.section`
  grid-column: 1/7;
  @media (min-width: 768px) {
    grid-column: 2/6;
  }
  @media (min-width: 1150px) {
    grid-column: 2/12;
  }
`

export const Container = styled.section`
  width: 1440px;
  max-width: 100%;
  margin: 2.5rem auto;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 1rem;
  @media (min-width: 1150px) {
    grid-template-columns: repeat(12, 1fr);
  }
`
